<template>
  <div class="usb">
    <!-- 参数 -->
    <Head :name="'五孔带USB电源面板'" :add="'/pc/ProUsb'" :adds="'/pc/ProUsbParam'" :color="true" />
    <!-- banner -->
    <div class="bannerBox">
      <div class="banner">
        <div class="bName">USB 充电插座二合一</div>
        <div class="bTit">五孔带USB电源面板</div>
        <!-- <div class="line"></div> -->
        <div class="bText">
          <img src="../../../assets/product/usbIcon1.png" class="bImg" />
          <div class="bTexts">高效快充</div>
        </div>
        <div class="bText">
          <img src="../../../assets/product/usbIcon2.png" class="bImg" />
          <div class="bTexts">智能分流</div>
        </div>
        <div class="bText">
          <img src="../../../assets/product/usbIcon3.png" class="bImg" />
          <div class="bTexts">自适应设备</div>
        </div>
        <div class="bText">
          <img src="../../../assets/product/usbIcon4.png" class="bImg" />
          <div class="bTexts">多重防护</div>
        </div>
      </div>
    </div>
    <div class="sec">
      <div class="secTit">三种色彩 演绎万种风情</div>
    </div>
    <div class="sec1">
      <el-carousel
        class="item"
        trigger="click"
        direction="vertical"
        :autoplay="true"
        :interval="3000"
      >
        <el-carousel-item class="im">
          <div class="txt">黑色</div>
        </el-carousel-item>
        <el-carousel-item class="im">
          <div class="txt">白色</div>
        </el-carousel-item>
        <el-carousel-item class="im">
          <div class="txt">浅香槟金</div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="sec2">
      <div class="s2Li">
        <div class="s2Tit">充电便捷</div>
        <div class="s2Text">娱乐充电两不误</div>
        <div class="s2Texts">可满足多个设备同时充电</div>
        <div class="s2Mask">卧室</div>
      </div>
      <div class="s2Li">
        <div class="s2Tit">无需走动</div>
        <div class="s2Text">沙发旁装一个，躺着坐着</div>
        <div class="s2Text">随便充，来客人充电更方便</div>
        <div class="s2Mask">客厅</div>
      </div>
      <div class="s2Li">
        <div class="s2Tit">边吃边充</div>
        <div class="s2Text">刷手机看视频</div>
        <div class="s2Text">手机怎么可以没电</div>
        <div class="s2Mask">餐厅</div>
      </div>
    </div>
    <div class="sec3">
      <div class="sec3Box">
        <div class="s3Lis">
          <div class="lisBox">
            <img src="../../../assets/product/usbSec1.png" class="s3Img" />
          </div>
        </div>
        <div class="s3Li">
          <div class="s3Tit">快速充电 整装待发</div>
          <div class="s3Text">2个USB充电插口，每个插口均支持 2.1A 快充，</div>
          <div class="s3Texts">满足多种设备同时充电。</div>
        </div>
      </div>
      <div class="sec3Box">
        <div class="s3Li">
          <div class="s3Tit">阻燃材料 杜绝隐患</div>
          <div class="s3Text">加强金属片，发热更低。</div>
          <div class="s3Texts">采用阻燃材料，保障可靠性。</div>
        </div>
        <div class="s3Lis">
          <div class="lisBox">
            <img src="../../../assets/product/usbSec2.png" class="s3Img" />
          </div>
        </div>
      </div>
      <div class="sec3Box">
        <div class="s3Lis">
          <div class="lisBox">
            <img src="../../../assets/product/usbSec3.png" class="s3Img" />
          </div>
        </div>
        <div class="s3Li">
          <div class="s3Tit">五孔供电 插头不打架</div>
          <div class="s3Text">实用性更强，可同时为多个电器和</div>
          <div class="s3Texts">电子设备供电，安全实用。</div>
        </div>
      </div>
      <div class="sec3Box">
        <div class="s3Li">
          <div class="s3Tit">智能分配电流 保护您的设备</div>
          <div class="s3Text">双口 2.1A 输出，同时为设备供电。</div>
          <div class="s3Texts">自适应电流设计，智能识别设备，智能分配电流。</div>
        </div>
        <div class="s3Lis">
          <div class="lisBox">
            <img src="../../../assets/product/usbSec4.png" class="s3Img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Head from "../../../components/Head.vue";
export default {
  components: {
    Head
  },
  data() {
    return {};
  },
  computed: {
    // swiper() {
    //   // return this.$refs.mySwiper.swiper;
    //   // console.log(this.$refs.mySwiper.swiper)
    // }
  },
  mounted() {
    // console.log(this.$refs.mySwiper.$swiper.slideTo(3, 1000, true))
    // console.log("Current Swiper instance object", this.swiper);
    // this.swiper.slsideTo(3, 1000, false);
  },
  methods: {
    change() {
      console.log("改变");
    }
  }
};
</script>
<style lang="less" scoped>
.bannerBox {
  width: 100%;
  height: 8rem;
  background: url("../../../assets/product/usbBanner.png");
  background-size: 100% 100%;
  .banner {
    width: 12rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #000;
    font-size: 0.24rem;
    line-height: 0.24rem;
    font-weight: 400;
    .bName {
      font-size: 0.3rem;
      line-height: 0.3rem;
      margin-top: 1.01rem;
    }
    .bTit {
      font-size: 0.48rem;
      line-height: 0.48rem;
      font-weight: bold;
      color: #333;
      margin-top: 0.21rem;
      margin-bottom: 1.58rem;
    }
    .bText {
      display: flex;
      align-items: center;
      margin-bottom: 0.4rem;
      &:last-of-type {
        margin-bottom: 0px;
      }
      &:nth-of-type(4) {
        .bImg {
          width: 0.46rem;
          height: 0.33rem;
          margin-right: 0.36rem;
        }
      }
      &:nth-of-type(5) {
        .bImg {
          width: 0.43rem;
          height: 0.36rem;
          margin-right: 0.38rem;
        }
      }
      &:nth-of-type(6) {
        .bImg {
          width: 0.4rem;
          height: 0.46rem;
          margin-right: 0.4rem;
        }
      }
      .bImg {
        width: 0.41rem;
        height: 0.41rem;
        margin-right: 0.41rem;
      }

      .bTexts {
        font-size: 0.24rem;
        line-height: 0.24rem;
        font-weight: 400;
        color: #000;
      }
    }
  }
}
.sec {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #333333;
  padding-top: 1.2rem;
  padding-bottom: 0.8rem;
  .secTit {
    font-size: 0.36rem;
    line-height: 0.36rem;
    font-weight: bold;
  }
}
.sec1 {
  width: 100%;
  height: 8rem;
  overflow: hidden;
  position: relative;
  .item {
    width: 100%;
    height: 100%;
  }
  /deep/.el-carousel__container {
    height: 100%;
  }
  /deep/ .el-carousel__indicators--vertical {
    left: 3.6rem;
    top: 2.55rem;
    transform: translateY(0);
  }
  .im {
    width: 100%;
    height: 100%;
    background: url("../../../assets/product/usb1.png");
    background-size: 100% 100%;
    &:nth-of-type(2) {
      background: url("../../../assets/product/usb2.png");
      background-size: 100% 100%;
    }
    &:nth-of-type(3) {
      background: url("../../../assets/product/usb3.png");
      background-size: 100% 100%;
    }
    .txt {
      font-size: 0.18rem;
      font-weight: 400;
      color: #333333;
      margin-top: 6rem;
    }
  }
  /deep/.el-carousel__indicator {
    border: 0.04rem solid #999;
    .el-carousel__button {
      background-color: #1e1e1e;
      width: 0.26rem;
      height: 0.26rem;
      border-radius: 50%;
      opacity: 1;
    }

    &:nth-of-type(2) {
      .el-carousel__button {
        background-color: #fff;
      }
    }
    &:nth-of-type(3) {
     .el-carousel__button {
        background-color: #b8b093;
      }
    }
  }
  /deep/.el-carousel__indicator--vertical {
    width: 0.46rem;
    height: 0.46rem;
    padding: 0px;
    margin-bottom: 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-sizing: border-box;
  }
  /deep/ .el-carousel__indicators .is-active {
    border: 0.04rem solid #ff9000;
  }
}
.sec2 {
  width: 100%;
  display: flex;
  .s2Li {
    flex: 1;
    height: 7.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 0.24rem;
    line-height: 0.24rem;
    font-weight: 400;
    color: #ffffff;
    background: url("../../../assets/product/usbS1.png");
    background-size: 100% 100%;
    &:nth-of-type(2) {
      background: url("../../../assets/product/usbS2.png");
      background-size: 100% 100%;
    }
    &:nth-of-type(3) {
      background: url("../../../assets/product/usbS3.png");
      background-size: 100% 100%;
    }
    .s2Tit {
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: 800;
    }
    .s2Text {
      margin-top: 0.2rem;
    }
    .s2Texts {
      margin-top: 0.12rem;
    }
    .s2Mask {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 0.8rem;
      line-height: 0.8rem;
      font-size: 0.3rem;
      font-weight: 400;
      color: #ff9000;
      background: rgba(0, 0, 0, 0.6);
    }
  }
}

.sec3 {
  width: 100%;
  padding: 1.2rem 0px;
  background-color: #fff;
  .sec3Box {
    width: 12rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    .s3Lis {
      width: 6.4rem;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .lisBox {
        width: 6.4rem;
        height: 4rem;
        .s3Img {
           width: 6.4rem;
          height: 4rem;
        }
      }
    }
    .s3Li {
      width: 5.6rem;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 0.3rem;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333;
      &:nth-of-type(7) {
        margin-bottom: 0px;
      }
      .s3Tit {
        font-size: 0.36rem;
        line-height: 0.36rem;
        font-weight: bold;
      }
      .s3Text {
        margin-top: 0.39rem;
      }
      .s3Texts {
        margin-top: 0.16rem;
      }
    }
  }
}
</style>
